.logos-wrapper {

	
	.logos-container {
		@include xy-grid-container();
		@include xy-grid;		
	}
	
	.logos {
		@include breakpoint(small) {
			@include xy-cell(12);
		}
		text-align:center;		
	}
}


.resume-wrapper {
	padding-top: 3rem;
	padding-bottom: 2rem;
	background-color: #e6e6e6;
	
	.resume-container {
		@include xy-grid-container();
		@include xy-grid;		
	}
	
	.resume {
		@include breakpoint(small) {
			@include xy-cell(12);
		}
		text-align:center;	
		h2 { margin-bottom: 2rem; }	
	}
}