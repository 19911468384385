$masonry-css-column-gap: 2rem;

.masonry-css {
	  	
  column-count: 1;
  column-gap: $masonry-css-column-gap;

  @include breakpoint(400px) {
    column-count: 1;
  }

  @include breakpoint(600px) {
    column-count: 1;
  }

  @include breakpoint(800px) {
    column-count: 2;
  }

  @include breakpoint(1100px) {
    column-count: 2;
  }
}

.masonry-css-item {
  break-inside: avoid;
  	margin-bottom: 2rem;
	transition: box-shadow 0.3s ease-in-out;
	-webkit-transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
	position: relative;
}

.masonry-css-item:not(.work-intro) {
	-webkit-box-shadow: 0px 1px 4px 0px rgba(84,84,84,1);
	-moz-box-shadow: 0px 1px 4px 0px rgba(84,84,84,1);
	box-shadow: 0px 1px 4px 0px rgba(84,84,84,1);
}

.masonry-css-item.exadel {
	-webkit-box-shadow: 0px 1px 4px 0px rgba(194,194,194,1);
	-moz-box-shadow: 0px 1px 4px 0px rgba(194,194,194,1);
	box-shadow: 0px 1px 4px 0px rgba(194,194,194,1);
}

.masonry-css-item:hover:not(.work-intro) {
	-webkit-transform: scale(1.01, 1.01);
    transform: scale(1.01, 1.01);
}

.image {
  display: block;
  width: 100%;
  height: auto;
}

.overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  height: 100%;
  width: 100%;
  opacity: 0;
  transition: .5s ease;
  background-color: #fff;
}

.masonry-css-item:hover .overlay {
  opacity: 1;
}

.text {
  color: black;
  font-size: 20px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  text-align: center;
  // font-family: 'Poppins', sans-serif;
}

.overlay.deliver-overlay {
	background: #f49606; /* Old browsers */
	background: -moz-linear-gradient(top, #f49606 0%, #ffbb06 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #f49606 0%,#ffbb06 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #f49606 0%,#ffbb06 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#f49606', endColorstr='#ffbb06',GradientType=0 ); /* IE6-9 */
	
	.text {
		color: white;
	}
}

.overlay.connect-overlay {
	background: #931c23; /* Old browsers */
	background: -moz-linear-gradient(top, #931c23 0%, #4c1c23 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #931c23 0%,#4c1c23 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #931c23 0%,#4c1c23 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#931c23', endColorstr='#4c1c23',GradientType=0 ); /* IE6-9 */
	
	.text {
		color: white;
	}
}


.overlay.drive-overlay {
	background: #60cadb; /* Old browsers */
	background: -moz-linear-gradient(top, #60cadb 0%, #0054bc 100%); /* FF3.6-15 */
	background: -webkit-linear-gradient(top, #60cadb 0%,#0054bc 100%); /* Chrome10-25,Safari5.1-6 */
	background: linear-gradient(to bottom, #60cadb 0%,#0054bc 100%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
	filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#60cadb', endColorstr='#0054bc',GradientType=0 ); /* IE6-9 */
	
	.text {
		color: white;
	}
}

.overlay.exadel-overlay {
	
	.text {
		color: #29abe2;
	}
}