.intro-container {
	@include xy-grid-container();
	@include xy-grid;
	
	.intro { 
	    @include breakpoint(small) {
	      @include xy-cell(12);
	    }
	    
	    padding:10rem 0;		
	}
	
	a { text-decoration: underline; transition: background-color 0.5s ease; }
	a:hover { background-color: $primary-color; text-decoration: none; }
	
	h3 { font-weight: 300; }
}


.work-wrapper {

	background-color: #f0f0f0;
	padding: 15rem 0 4rem;

	.work-container {
		@include xy-grid-container();
		@include xy-grid;
		
		.work { 
		    @include breakpoint(small) {
		      @include xy-cell(12);
		    }
		    
		    .work-intro {
			    margin-top: -8rem;
		    }
		}
	}
	
}