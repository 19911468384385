// .cs-wrapper {
	// background-color: #f0f0f0;
// }

.cs-intro-container {
	@include xy-grid-container();
	@include xy-grid;
		
	.cs-intro { 
	    @include breakpoint(small) {
	      @include xy-cell(12);
	    }
	    
	    padding:2rem 0;		
	}
	
	h4 { color: #6b6b6b; font-weight: 300; }
}

.cs-about-container {
	@include xy-grid-container();
	@include xy-grid;
	
	img { margin-bottom: 4rem; }
	
	.cs-about { 
	    @include breakpoint(small) {
	      @include xy-cell(12);
	    }
	    
	}
}

.cs-role-container {
	@include xy-grid-container();
	@include xy-grid;
	
	.cs-role { 
	    @include breakpoint(small) {
	      @include xy-cell(12);
	    }
	    
	    padding:2rem 0;		
	}
}


.cs-process-container {
	@include xy-grid-container();
	@include xy-grid;	
	margin-bottom: 2rem;
	 
	p { margin-bottom: 1rem; }
	img { margin: 2rem 0; }
	
	.cs-left {
		@include breakpoint(small) {
			@include xy-cell(12);
		}
		
		@include breakpoint(medium) {
			@include xy-cell(4);
		}	
	}
	
	.cs-right {
		@include breakpoint(small) {
			@include xy-cell(12);
		}
		
		@include breakpoint(medium) {
			@include xy-cell(8);
		}	
	}
	
	.cs-full {
		@include breakpoint(small) {
			@include xy-cell(12);
		}	
	}	
	
}

.about-header {
	margin-bottom: 2rem;
}