.top-bar, .top-bar ul {
    background-color: $white;
}

.top-bar-container {
	@include xy-grid-container(59rem);
	@include xy-grid;
	
	.top-bar { 
	    @include breakpoint(small) {
	      @include xy-cell(12);
	    }	
	    
	    padding-top: 2rem;
	    padding-bottom: 2rem;
	    
	    h5 a { background-color: $primary-color; }
	
	}
}

.dropdown.menu > li.is-dropdown-submenu-parent > a::after {
    display: none;
}

.dropdown.menu a:hover {
    background-color: $primary-color;
    color: $white;
}

.is-dropdown-submenu {
    border: none;
    background-color: #f4f4f4 !important;
}